<template>
  <div>
    <form class="form-contact row" @submit.prevent="submitForm()">
      <div class="col-12">
        <label for="name">Nome*</label>
        <input aria-label="Nome" id="name" type="text" name="name" v-model="form.name" placeholder="Nome*" required>
      </div>
      <div class="col-md-6 col-12">
        <label for="email">E-mail</label>
        <input aria-label="E-mail" id="email" type="email" name="email" v-model="form.email" placeholder="E-mail">
      </div>
      <div class="col-md-6 col-12">
        <label for="phone">Telefone*</label>
        <the-mask aria-label="Telefone" id="phone" type="tel" v-model="form.phone" :mask="['(##) ####-####', '(##) #####-####']" required placeholder="(99) 99999-9999" />
      </div>
      <div class="col-12">
        <label for="subject">Assunto</label>
        <input aria-label="Assunto" id="subject" type="text" name="subject" v-model="form.subject" placeholder="Assunto">
      </div>
      <div class="col-12">
        <label for="message">Mensagem</label>
        <textarea name="message" id="message" v-model="text" placeholder="Sua mensagem" rows="6"></textarea>
      </div>
      <div class="col-12">
        <button type="submit" :disabled="disabled">{{btnText}}</button>
      </div>
    </form>
  </div>
</template>
<script>
import {TheMask} from 'vue-the-mask'
import axios from 'axios'
export default {
  props: ['route'],
  components: {TheMask},
  data(){
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        text: ""
      },
      disabled: false,
      btnText: "Enviar"
    }
  },
  methods: {
    submitForm() {
      this.btnText = 'Enviando'
      this.disabled = true

      axios.post(this.route, this.form)
      .then(response => {
        console.log(response);

        this.resetForm()
        this.btnText = 'Enviar'
        this.disabled = false
        this.$swal("Obrigado :D", "Dados enviados com sucesso!", "success")
      })
      .catch(e => {
        this.btnText = 'Enviar'
        this.disabled = false
        this.$swal("Oops!", "Houve um erro ao enviar seus dados! Por favor, tente novamente.", "error")
        console.error(e)
      })
    },
    resetForm () {
      this.form = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        text: ""
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "resources/assets/sass/website/_variables.scss";

</style>
